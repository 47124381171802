import React from "react";

const Footer = () => {
  return (
    <footer
      data-testid="footer-seccion"
      className="bg-gray-900 text-white py-4"
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <p className="text-center text-sm sm:text-base">
          &copy; 2024 Somos Nodos. Todos los derechos reservados.
        </p>
        <div className="flex justify-center space-x-4">
          <a
            href="https://www.youtube.com/channel/UCVYQ6mxQymV4kBYNnfwrN7g"
            className="text-gray-400 hover:text-white text-sm sm:text-base"
          >
            YouTube
          </a>
          <a
            href="https://www.linkedin.com/company/somos-nodos"
            className="text-gray-400 hover:text-white text-sm sm:text-base"
          >
            LinkedIn
          </a>
          <a
            href="https://instagram.com/somos_nodos"
            className="text-gray-400 hover:text-white text-sm sm:text-base"
          >
            Instagram
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
