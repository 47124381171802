import React from "react";

const FollowUs = () => {
  return (
    <section className="bg-dark py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center md:space-x-8">
        <div className="md:w-1/2 text-center md:text-left mb-6 md:mb-0">
          <h2 className="text-3xl font-bold text-white mb-4">Síguenos</h2>
          <p className="text-base sm:text-lg text-gray-300">
            Conéctate con nosotros en redes sociales y mantente al día con
            nuestros episodios.
          </p>
        </div>
        <div className="md:w-1/3 bg-gray-800 text-white p-6 rounded-lg shadow-lg grid grid-cols-3 gap-4 sm:gap-6 items-center justify-items-center">
          <a
            href="https://www.youtube.com/channel/UCVYQ6mxQymV4kBYNnfwrN7g"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <i className="fab fa-youtube fa-2x sm:fa-3x"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/somos-nodos"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <i className="fab fa-linkedin fa-2x sm:fa-3x"></i>
          </a>
          <a
            href="https://instagram.com/somos_nodos"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <i className="fab fa-instagram fa-2x sm:fa-3x"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FollowUs;
