import React from "react";

const AboutUs = () => {
  return (
    <section id="about-us" className="bg-dark py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center pt-10 pb-10">
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-5">
            Bienvenidos a Somos Nodos
          </h2>
          <p className="text-base sm:text-lg text-gray-300 mb-6 text-justify leading-relaxed">
            En <strong>Somos Nodos</strong>, conectamos con personas de diversas
            áreas de la vida, con el propósito de compartir aprendizajes que
            inspiran y educan. A través de conversaciones auténticas, exploramos
            valores como la resiliencia, la adaptabilidad y la actitud para el
            aprendizaje continuo.
          </p>
          <p className="text-base sm:text-lg text-gray-300 mb-6 text-justify leading-relaxed">
            Nuestra misión es construir una comunidad de oyentes apasionados por
            crecer y aprender de las experiencias de profesionales que marcan la
            diferencia. Cada episodio es una invitación a reflexionar y
            descubrir ideas que impacten tu vida de manera positiva.
          </p>
          <p className="text-base sm:text-lg text-gray-300 mb-6 text-justify leading-relaxed">
            Únete a <strong>Somos Nodos</strong> y sé parte de una comunidad que
            valora el aprendizaje, el crecimiento personal, y el impacto que las
            historias de otros pueden tener en nuestras vidas.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
