import React from "react";

const Sponsor = () => {
  return (
    <section className="bg-dark py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center md:space-x-8">
        <div className="md:w-1/3 bg-gray-800 text-white p-8 rounded-lg shadow-lg flex flex-col items-center space-y-4">
          <a
            href="https://buymeacoffee.com/somosnodos"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 hover:text-white"
          >
            <i className="fas fa-coffee fa-4x"></i>
          </a>
        </div>

        <div className="md:w-1/2 text-center md:text-left mt-6 md:mt-0">
          <h2 className="text-3xl font-bold text-white mb-4">
            Apoya nuestro proyecto con un ☕
          </h2>
          <p className="text-lg text-gray-300">
            Tu contribución nos ayuda a seguir creando contenido valioso.
            ¡Gracias de antemano!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Sponsor;
